<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.7466 22.4219H32.8125V19.9583C32.8272 18.0143 31.2656 16.4255 29.3219 16.4062H8.75V8.56789H11.4844C11.6529 8.56789 11.8118 8.49018 11.9156 8.35747C12.0192 8.22475 12.0558 8.05145 12.015 7.88803L10.5546 2.05452C10.494 1.81179 10.2761 1.64143 10.0259 1.64063H6.38039C6.12938 1.64036 5.91042 1.81099 5.84927 2.05452L4.39129 7.88803C4.35043 8.05145 4.38702 8.22475 4.49062 8.35747C4.5945 8.49018 4.75338 8.56789 4.92188 8.56789H7.65625V16.4062H5.13123C3.18752 16.4255 1.62594 18.0143 1.64062 19.9583V22.4219H1.25343C0.561562 22.4224 0.000534058 22.9834 0 23.6753V30.625C0 30.927 0.244865 31.1719 0.546875 31.1719H1.64062V32.0256C1.64143 32.7618 2.23824 33.3586 2.97443 33.3594H32.0256C32.7618 33.3586 33.3586 32.7618 33.3594 32.0256V31.1719H34.4531C34.7551 31.1719 35 30.927 35 30.625V23.6753C34.9995 22.9834 34.4384 22.4224 33.7466 22.4219ZM5.62229 7.47414L6.80683 2.73438H9.59942L10.784 7.47414H5.62229ZM2.71568 19.9583C2.7058 18.613 3.7862 17.5136 5.13123 17.5H29.3219C30.6669 17.5136 31.7473 18.613 31.7374 19.9583V22.4219H31.3316C30.6397 22.4224 30.0787 22.9834 30.0781 23.6753V25.1562H4.92188V23.6753C4.92134 22.9834 4.36031 22.4224 3.66844 22.4219H2.71568V19.9583ZM30.0781 26.25V30.0781H4.92188V26.25H30.0781ZM1.09375 30.0781V23.6753C1.09375 23.5872 1.16531 23.5156 1.25343 23.5156H3.66844C3.75656 23.5156 3.82812 23.5872 3.82812 23.6753V30.0781H1.09375ZM32.2656 32.0256C32.2654 32.158 32.158 32.2654 32.0256 32.2656H2.97443C2.84199 32.2654 2.73464 32.158 2.73438 32.0256V31.1719H32.2656V32.0256ZM33.9062 30.0781H31.1719V23.6753C31.1719 23.5872 31.2434 23.5156 31.3316 23.5156H33.7466C33.8347 23.5156 33.9062 23.5872 33.9062 23.6753V30.0781Z"
      fill="currentColor"
    />
    <path
      d="M7.65625 18.5938H8.75V19.6875H7.65625V18.5938Z"
      fill="currentColor"
    />
    <path
      d="M7.65625 21.875H8.75V22.9688H7.65625V21.875Z"
      fill="currentColor"
    />
    <path
      d="M26.25 18.5938H27.3438V19.6875H26.25V18.5938Z"
      fill="currentColor"
    />
    <path d="M26.25 21.875H27.3438V22.9688H26.25V21.875Z" fill="currentColor" />
    <path
      d="M16.9531 18.5938H18.0469V19.6875H16.9531V18.5938Z"
      fill="currentColor"
    />
    <path
      d="M16.9531 21.875H18.0469V22.9688H16.9531V21.875Z"
      fill="currentColor"
    />
    <path
      d="M14.2188 14.7656H32.2656C32.5676 14.7656 32.8125 14.5208 32.8125 14.2188V2.73438C32.8125 2.43237 32.5676 2.1875 32.2656 2.1875H14.2188C13.9167 2.1875 13.6719 2.43237 13.6719 2.73438V14.2188C13.6719 14.5208 13.9167 14.7656 14.2188 14.7656ZM14.7656 3.28125H31.7188V13.6719H14.7656V3.28125Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Living',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
};
</script>
