import Vue from 'vue';
import router from '@/router';
import VueYandexMetrika from 'vue-yandex-metrika';
import VueGtm from '@gtm-support/vue2-gtm';
import { getCookie, setCookie } from '@/utils/cookieUtils';

export default {
  state: () => ({
    utm: null,
    tracking_data: null,
    analytics: null,
  }),
  mutations: {
    SET_UTM(state, data) {
      state.utm = data;
    },

    SET_ANALYTICS_SETTINGS(state, data) {
      state.analytics = data;
    },

    SET_TRACKING_DATA(state, data) {
      state.tracking_data = data;
    },
  },
  actions: {
    SET_ANALYTICS_SETTINGS({ commit, getters, dispatch }, analytics) {
      commit('SET_ANALYTICS_SETTINGS', analytics);
      if (
        getters.inCrmApplication ||
        // eslint-disable-next-line no-undef
        process.env.NODE_ENV !== 'production'
      ) {
        return;
      }
      dispatch('CREATE_ANALYTICS', analytics);
    },
    CREATE_ANALYTICS({ state }, analytics) {
      if (analytics.metrika != null) {
        Vue.use(VueYandexMetrika, {
          id: analytics.metrika,
          debug: true,
          // eslint-disable-next-line no-undef
          env: process.env.NODE_ENV,
          options: {
            webvisor: true,
          },
        });
      }
      if (analytics.gtm) {
        Vue.use(VueGtm, {
          id: analytics.gtm,
          defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
          compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
          nonce: '2726c7f26c', // Will add `nonce` to the script tag
          enabled: true,
          debug: true, // Whether or not display console logs debugs (optional)
          loadScript: true,
          vueRouter: router,
          trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
        });
      }

      if (
        window.RPTAnalytics &&
        (state.analytics.rpt.id != analytics.rpt.id ||
          state.analytics.rpt.uid != analytics.rpt.uid ||
          state.analytics.rpt.aid != analytics.rpt.aid)
      ) {
        window.RPTAnalytics.destroy();
      }
      if (analytics.rpt.id != null) {
        let options = {
          sessionType: 'public module',
          userType: analytics.rpt.utp,
          userId: analytics.rpt.uid,
          addonId: analytics.rpt.aid,
        };
        // TODO: Move script to index.html && index.php
        (function () {
          var analyticsScript = document.createElement('script');
          analyticsScript.src =
            'https://admin.realtyprotech.com/services/analytics/script.js?v=1.2';
          analyticsScript.addEventListener('load', function () {
            window.RPTAnalytics.init(analytics.rpt.id, {}, options).then(
              () => {}
            );
          });
          document.head.appendChild(analyticsScript);
        })();
      }
    },

    SET_UTM(ctx, data) {
      const cookie_key = `utm_${ctx.getters.catalogSettings.short_type}_${ctx.getters.catalogSettings.id}`;
      const utm = getCookie(cookie_key);
      if (utm) {
        ctx.commit('SET_UTM', JSON.parse(utm));
      } else {
        if (
          data.utm_source ||
          data.utm_campaign ||
          data.utm_content ||
          data.utm_medium ||
          data.utm_term
        ) {
          setCookie(cookie_key, JSON.stringify(data), {
            secure: true,
            'max-age': 604800,
          });
          ctx.commit('SET_UTM', data);
        }
      }
    },
    SET_TRACKING_DATA(ctx, data) {
      const cookie_key = `tracking_data_${ctx.getters.catalogSettings.short_type}_${ctx.getters.catalogSettings.id}`;

      const tracking_data = getCookie(cookie_key);
      if (tracking_data) {
        ctx.commit('SET_TRACKING_DATA', JSON.parse(tracking_data));
      } else {
        if (data.yclid || data.gclid || data.referrer) {
          setCookie(cookie_key, JSON.stringify(data), {
            secure: true,
            'max-age': 604800,
          });
          ctx.commit('SET_TRACKING_DATA', data);
        }
      }
    },
  },
  getters: {
    utm(state) {
      return state.utm;
    },
    tracking_data(state) {
      let arr = {};
      if (state.analytics.metrika != null) {
        const ycounter = getCookie('_ym_counter');
        if (ycounter && ycounter == state.analytics.metrika) {
          arr['_ym_counter'] = state.analytics.metrika;
          if (getCookie('_ym_uid')) {
            arr['_ym_uid'] = getCookie('_ym_uid');
          }
          if (state.tracking_data != null) {
            if (state.tracking_data.yclid) {
              arr['yclid'] = state.tracking_data.yclid;
            }
          }
        }
      }
      if (state.tracking_data != null) {
        if (state.tracking_data.referrer) {
          arr['referrer'] = state.tracking_data.referrer;
        }
        if (state.tracking_data.gclid) {
          arr['gclid'] = state.tracking_data.gclid;
        }
      }
      if (getCookie('roistat_visit')) {
        arr['roistat'] = getCookie('roistat_visit');
      }
      if (window.RPTAnalytics) {
        arr['RPTAnalytics_uid'] = window.RPTAnalytics.getUid();
      }
      return arr;
    },
  },
};
