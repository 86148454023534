import { getCookie, setCookie } from '@/utils/cookieUtils';

export default {
  state: () => ({
    favorites: [],
  }),
  mutations: {
    SET_FAVORITES: (state, data) => {
      state.favorites = data;
    },
  },
  actions: {
    INIT_FAVORITES: ({ commit, getters }) => {
      const cookieKey = `favorites-${getters.catalogSettings.short_type}-${getters.catalogSettings.id}`;
      const favorites = JSON.parse(getCookie(cookieKey) || '[]');
      commit('SET_FAVORITES', favorites);
    },

    TOGGLE_LIKE({ getters, commit }, id) {
      const cookieKey = `favorites-${getters.catalogSettings.short_type}-${getters.catalogSettings.id}`;
      const favorites = new Set(getters.favorites);

      if (favorites.has(id)) {
        favorites.delete(id);
      } else {
        favorites.add(id);
      }
      const updatedFavorites = [...favorites];
      commit('SET_FAVORITES', updatedFavorites);

      setCookie(cookieKey, JSON.stringify(updatedFavorites), {
        secure: true,
        'max-age': 315360000,
      });
    },
    PUSH_FAVORITES_FROM_PARAMS(ctx, value) {
      ctx.commit('SET_FAVORITES', value);
    },
  },
  getters: {
    isLiked: (state) => (id) => {
      return state.favorites?.find((like) => like == id);
    },
    favorites(state) {
      return state.favorites;
    },
  },
};
