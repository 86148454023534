<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.4167 7.58301H21.5833C21.2612 7.58301 21 7.8442 21 8.16634V22.1663H18.0833V21.583H18.6667C18.9888 21.583 19.25 21.3218 19.25 20.9997C19.25 20.6775 18.9888 20.4163 18.6667 20.4163H12.8333C12.5112 20.4163 12.25 20.6775 12.25 20.9997C12.25 21.3218 12.5112 21.583 12.8333 21.583H13.4167V22.1663H7.58333V21.583H8.16667C8.48881 21.583 8.75 21.3218 8.75 20.9997C8.75 20.6775 8.48881 20.4163 8.16667 20.4163H2.33333C2.01119 20.4163 1.75 20.6775 1.75 20.9997C1.75 21.3218 2.01119 21.583 2.33333 21.583H2.91667V22.1663H0.583333C0.261187 22.1663 0 22.4275 0 22.7497V34.4163C0 34.7385 0.261187 34.9997 0.583333 34.9997H34.4167C34.7388 34.9997 35 34.7385 35 34.4163V8.16634C35 7.8442 34.7388 7.58301 34.4167 7.58301ZM14.5833 21.583H16.9167V22.1663H14.5833V21.583ZM4.08333 21.583H6.41667V22.1663H4.08333V21.583ZM9.33333 33.833H1.16667V26.833H9.33333V33.833ZM9.33333 25.6663H1.16667V23.333H9.33333V25.6663ZM21 33.833H10.5V23.333H21V33.833ZM33.8333 33.833H22.1667V18.083H33.8333V33.833ZM33.8333 16.9163H22.1667V8.74967H33.8333V16.9163Z"
      fill="currentColor"
    />
    <path
      d="M12.2503 32.6667H19.2503C19.5725 32.6667 19.8337 32.4055 19.8337 32.0833V25.0833C19.8337 24.7612 19.5725 24.5 19.2503 24.5H12.2503C11.9282 24.5 11.667 24.7612 11.667 25.0833V32.0833C11.667 32.4055 11.9282 32.6667 12.2503 32.6667ZM12.8337 25.6667H18.667V31.5H12.8337V25.6667Z"
      fill="currentColor"
    />
    <path
      d="M23.3333 16.333C23.6555 16.333 23.9167 16.0718 23.9167 15.7497V13.4163C23.9167 13.0942 23.6555 12.833 23.3333 12.833C23.0112 12.833 22.75 13.0942 22.75 13.4163V15.7497C22.75 16.0718 23.0112 16.333 23.3333 16.333Z"
      fill="currentColor"
    />
    <path
      d="M23.3333 22.167C23.6555 22.167 23.9167 21.9058 23.9167 21.5837V19.2503C23.9167 18.9282 23.6555 18.667 23.3333 18.667C23.0112 18.667 22.75 18.9282 22.75 19.2503V21.5837C22.75 21.9058 23.0112 22.167 23.3333 22.167Z"
      fill="currentColor"
    />
    <path
      d="M1.37542 10.3644C1.48057 10.4522 1.61321 10.5001 1.75022 10.5H19.2502C19.5724 10.5004 19.8339 10.2397 19.8344 9.91754C19.8346 9.7806 19.7866 9.6479 19.6988 9.54275L13.8655 2.54275C13.7544 2.40982 13.5901 2.33311 13.4169 2.33333H12.8335V0.583333C12.8335 0.261187 12.5724 0 12.2502 0H8.75022C8.42807 0 8.16688 0.261187 8.16688 0.583333V2.33333H7.58355C7.41037 2.33311 7.24602 2.40982 7.13497 2.54275L1.30163 9.54275C1.09513 9.79001 1.12816 10.1579 1.37542 10.3644ZM9.33355 1.16667H11.6669V2.33333H9.33355V1.16667ZM7.85713 3.5H13.1433L18.0048 9.33333H2.99563L7.85713 3.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Kitchen',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
};
</script>
