import Interactive from './Interactive.vue';
import Heart from './Heart.vue';
import Telegram from './Telegram.vue';
import Whatsapp from './Whatsapp.vue';
import Viber from './Viber.vue';
import Menu from './Menu.vue';
import PdfFile from './PdfFile.vue';
import Living from './Living.vue';
import Kitchen from './Kitchen.vue';
import Renovation from './Renovation.vue';
import Balcony from './Balcony.vue';
import WindowView from './WindowView.vue';
import Bathroom from './Bathroom.vue';

import PremisesLayout from './PremisesLayout.vue';

export const RPTIcons = {
  Interactive,
  PremisesLayout,
  RPTHeart: Heart,
  MenuFullHeight: Menu,
  Telegram,
  Whatsapp,
  Viber,
  PdfFile,
  Living,
  Kitchen,
  Renovation,
  Balcony,
  WindowView,
  Bathroom,
};
