export default {
  state: () => ({
    crm: {},
    inCrmApplication: false,
    isAdmin: false,
  }),
  mutations: {
    SET_CRM: (state, crm) => {
      state.crm = crm;
      state.inCrmApplication = true;
    },
    SET_IS_ADMIN: (state, isAdmin) => {
      state.isAdmin = isAdmin;
    },
  },
  actions: {
    SET_CRM(ctx, crm) {
      ctx.commit('SET_CRM', crm);
    },
    SET_IS_ADMIN(ctx, isAdmin) {
      ctx.commit('SET_IS_ADMIN', isAdmin);
    },
  },
  getters: {
    isAdmin(state) {
      return state.isAdmin;
    },
    inCrmApplication(state) {
      return state.inCrmApplication;
    },
    crm(state) {
      return state.crm;
    },
  },
};
