import {
  formatCurrency,
  formatCompactCurrency,
  formatNumber,
} from '@dmlnc/hello_world_shared';

export default {
  install(Vue) {
    Vue.prototype.$formatCurrency = formatCurrency;
    Vue.prototype.$formatCompactCurrency = formatCompactCurrency;

    Vue.prototype.$formatNumber = formatNumber;
  },
};
