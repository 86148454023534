import { calculateSimilarity } from '@/utils/similarityUtils';
import router from '@/router';

export default {
  state: () => ({
    group: null,
    project: null,
    estates: [],
    sections: [],
    storeys: [],
    instances: [],
    layouts: [],
    tags: [],
    statuses: [],
    promoactions: [],
  }),
  getters: {
    free_instances(state) {
      return state.instances.filter((i) => {
        return i.status.type == 1;
      });
    },
    // Interactive
    notHaveChilds: (state) => (id, view) => {
      switch (view) {
        case 'InteractiveCatalogue':
          return false;
        case 'Estate':
          return !state.estates?.find((estate) => estate.id === id)?.free_count;
        case 'Section':
          return !state.sections?.find((section) => section.id === id)
            ?.free_count;
        case 'Storey':
          return !state.storeys?.find((storey) => storey.id === id)?.free_count;
        case 'Instance':
          return !(
            state.instances?.find((estate) => estate.id === id)?.status.type ===
            1
          );
        default:
          return true;
      }
    },

    group(state) {
      return state.group;
    },

    project(state) {
      return state.project;
    },
    estates(state) {
      return state.estates;
    },
    sections(state) {
      return state.sections;
    },
    storeys(state) {
      return state.storeys;
    },
    instances(state) {
      return state.instances;
    },
    layouts(state) {
      return state.layouts;
    },

    tags(state) {
      return state.tags;
    },
    statuses(state) {
      return state.statuses;
    },
    promoactions(state) {
      return state.promoactions;
    },
  },
  mutations: {
    SET_GROUP: (state, response) => {
      state.group = response;
    },
    SET_PROJECT: (state, response) => {
      state.project = response;
    },
    SET_ESTATES: (state, response) => {
      state.estates = response;
    },
    SET_SECTIONS: (state, response) => {
      state.sections = response;
    },
    SET_STOREYS: (state, response) => {
      state.storeys = response;
    },
    SET_INSTANCES: (state, response) => {
      state.instances = response;
    },
    SET_LAYOUTS: (state, response) => {
      state.layouts = response;
    },
    SET_PROMOACTIONS: (state, response) => {
      state.promoactions = response;
    },
    SET_STATUSES: (state, response) => {
      state.statuses = response;
    },
    SET_TAGS: (state, response) => {
      state.tags = response;
    },
  },
  actions: {
    // async GET_PROJECT_GROUP(ctx, id = 0) {
    //   ctx.dispatch('LOAD_START', 'GET_PROJECT_GROUP');
    //   await ctx
    //     .dispatch('axiosWrapped', {
    //       url: `public/project-groups/${id}?a=${ctx.rootState.admin}`,
    //     })
    //     .then(function (response) {
    //       const group = response.data.data;
    //       document.title = group.name;
    //       ctx.commit('SET_PROJECT_GROUP', group);
    //       console.log('get project group finish');
    //     })
    //     .catch(function (error) {
    //       console.log(error, 'error in fetching project group');
    //     })
    //     .finally(() => {
    //       ctx.dispatch('LOAD_END', 'GET_PROJECT_GROUP');
    //     });
    // },

    async GET_PROJECT(ctx, id = 0) {
      ctx.dispatch('LOAD_START', 'GET_PROJECT');
      return await ctx
        .dispatch('axiosWrapped', {
          url: `public/projects/${id}`,
        })
        .then(function (res) {
          const response = res.data.data;
          ctx.commit('SET_CURRENT_CURRENCY', response.project.currency);
          ctx.commit('SET_AREA_UNIT', response.project.area_unit);
          ctx.commit('SET_PROJECT', response.project);

          ctx.commit('SET_LAYOUTS', response.layouts);
          ctx.commit('SET_PROMOACTIONS', response.promoactions);
          ctx.commit('SET_STATUSES', response.statuses);
          ctx.commit('SET_TAGS', response.tags);
          console.log('get project finish');
        })
        .catch(function (error) {
          console.log(error, 'error in fetching project');
          router.push({ name: 'error-404' });
        })
        .finally(() => {
          ctx.dispatch('LOAD_END', 'GET_PROJECT');
        });
    },

    GET_SIMILAR({ getters }, { instance, count = 3 }) {
      let freeInstances = getters.free_instances;
      freeInstances = freeInstances.filter((item) => item.id !== instance.id);

      let similarities = freeInstances.map((item) => {
        const [similarity] = calculateSimilarity(instance, item);
        return { similarity, instance: item };
      });

      similarities.sort((a, b) => b.similarity[0] - a.similarity[0]);
      return similarities.slice(0, count);
      // return similarities.map((item) => item.instance);
    },

    async GET_CHESS(ctx, id) {
      ctx.dispatch('LOAD_START', 'GET_CHESS');
      return await ctx
        .dispatch('axiosWrapped', {
          url: `public/projects/${id}/chess`,
        })
        .then(async function (response) {
          console.log('get chess finish');
          return response.data.data;
        })
        .catch(function (error) {
          console.log(error, 'error in fetching chess');
          router.push({ name: 'error-404' });
          ctx.dispatch('LOAD_END', 'GET_CHESS');
        })
        .finally(() => {});
    },

    PROCESS_CHESS(ctx, response) {
      console.log('process chess');
      let instances = [];
      let sections = [];
      let storeys = [];

      response?.forEach((estate) => {
        estate.min_price = Infinity;
        estate.free_count = 0;
        estate.free_counts = {};

        estate.sections?.forEach((section) => {
          section.min_price = Infinity;
          section.free_count = 0;
          section.free_counts = {};

          section.storeys?.forEach((storey) => {
            storey.min_price = Infinity;
            storey.free_count = 0;
            storey.free_counts = {};
            storey.section_id = section.id;

            storey.instances.forEach((instance) => {
              ctx.dispatch('processInstance', {
                instance,
                storey,
                section,
                estate,
              });

              instances.push(instance);
            });

            ctx.dispatch('updateMinPriceAndCounts', {
              target: section,
              source: storey,
            });
            storeys.push(storey);
          });

          ctx.dispatch('updateMinPriceAndCounts', {
            target: estate,
            source: section,
          });
          sections.push(section);
        });
      });

      ctx.commit('SET_STOREYS', storeys);
      ctx.commit('SET_SECTIONS', sections);
      ctx.commit('SET_INSTANCES', instances);
      ctx.commit('SET_ESTATES', response);

      console.log('set instances finish');

      ctx.dispatch('LOAD_END', 'GET_CHESS');
    },

    processInstance(ctx, { instance, storey, section, estate }) {
      const layoutsMap = new Map(
        ctx.getters.layouts.map((item) => [item.id, item])
      );
      const statusesMap = new Map(
        Array.isArray(ctx.getters.statuses)
          ? ctx.getters.statuses.map((item) => [item.id, item])
          : []
      );
      const promoactionsMap = new Map(
        ctx.getters.promoactions.map((item) => [item.id, item])
      );
      const tagsMap = new Map(ctx.getters.tags.map((item) => [item.id, item]));

      instance.layout = layoutsMap.get(instance.layout_id) || null;
      instance.status = statusesMap.get(instance.status_id) ||
        ctx.getters.statuses[0] || {
          id: 0,
          type: 1,
          name: '',
          color: 'var(--color-primary)',
        };

      instance.promoactions = (instance.promoactions || [])
        .map((id) => promoactionsMap.get(id))
        .filter(Boolean);

      instance.tags = (instance.tags || []).map((id) => tagsMap.get(id));

      instance.storey = {
        id: storey.id,
        number: storey.number,
        order: storey.order,
      };
      instance.estate = {
        id: estate.id,
        order: estate.order,
        name: estate.name,
        type: estate.type,
      };
      instance.section = { number: section.number };

      ctx.dispatch('applyPromoDiscount', instance);

      if (instance.price && instance.layout?.area_total) {
        instance.price_m2 = instance.price / instance.layout.area_total;
      }

      ctx.dispatch('updateStoreyFreeCounts', { storey, instance });
    },

    updateMinPriceAndCounts(ctx, { target, source }) {
      if (target.min_price > source.min_price) {
        target.min_price = source.min_price;
      }
      target.free_count += source.free_count;

      Object.keys(source.free_counts).forEach((key) => {
        if (target.free_counts[key]) {
          target.free_counts[key].count += source.free_counts[key].count;
          target.free_counts[key].min_price = Math.min(
            target.free_counts[key].min_price,
            source.free_counts[key].min_price
          );
        } else {
          target.free_counts[key] = { ...source.free_counts[key] };
        }
      });
    },

    applyPromoDiscount(ctx, instance) {
      if (!instance.promoactions.length || instance.price == null) return;

      let maxSale = Math.max(
        ...instance.promoactions.map((item) => item.sale || 0),
        0
      );
      instance.old_price = instance.price;
      instance.price = instance.price - (instance.price * maxSale) / 100;
    },

    updateStoreyFreeCounts(ctx, { storey, instance }) {
      if (instance.status.type !== 1) return;

      storey.free_count++;
      storey.min_price = Math.min(
        storey.min_price,
        instance.price + instance.extra_price || Infinity
      );

      const key = instance.layout?.label || 'null';

      if (!storey.free_counts[key]) {
        storey.free_counts[key] = {
          count: 1,
          min_price: instance.price + instance.extra_price ?? Infinity,
        };
      } else {
        storey.free_counts[key].count++;
        storey.free_counts[key].min_price = Math.min(
          storey.free_counts[key].min_price,
          instance.price + instance.extra_price || Infinity
        );
      }
    },
  },
};
