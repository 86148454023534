import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const modalTypes = {
  order: {
    component: 'OrderModal',
  },
  callback: {
    component: 'CallbackModal',
  },
  catalogSelect: {
    component: 'catalogSelectModal',
  },
  filter: {
    component: 'filterModal',
  },
  crmSuccessLink: {
    component: 'SuccessCrmLinkModal',
  },
  errorModal: {
    component: 'ErrorModal',
  },
};

export default {
  state: () => ({
    modals: [],
  }),
  getters: {
    modalTypes() {
      return modalTypes;
    },
    activeModal(state) {
      return state.modals[state.modals.length - 1] || null;
    },
    modalCount(state) {
      return state.modals.length;
    },
  },
  mutations: {
    OPEN_MODAL(state, modal) {
      state.modals.push(modal);
      window.eventBus.$emit('modal:open', modal);
    },
    CLOSE_MODAL(state, index) {
      const modal = state.modals[index];
      state.modals.splice(index, 1);
      window.eventBus.$emit('modal:close', modal);
    },
    CLOSE_ALL_MODALS(state) {
      state.modals = [];
      window.eventBus.$emit('modal:closeAll');
    },
    UPDATE_MODAL(state, { modal, index }) {
      state.modals.splice(index, 1, modal);
      window.eventBus.$emit('modal:update', modal);
    },
  },
  actions: {
    OPEN_MODAL({ commit }, modalConfig) {
      if (!modalConfig.type || !modalTypes[modalConfig.type]) {
        console.warn(`Неизвестный тип модального окна: ${modalConfig.type}`);
        return false;
      }

      const defaultModalConfig = {
        payload: {},
        uuid: `modal-uuid-${Date.now()}`,
      };

      const modal = { ...defaultModalConfig, ...modalConfig };

      commit('OPEN_MODAL', modal);
      return modal;
    },

    CLOSE_MODAL({ commit, state }) {
      if (state.modals.length === 0) return;

      const lastIndex = state.modals.length - 1;
      commit('CLOSE_MODAL', lastIndex);
    },

    CLOSE_MODAL_BY_ID({ commit, state }, id) {
      const index = state.modals.findIndex((modal) => modal.id === id);
      if (index === -1) return;

      commit('CLOSE_MODAL', index);
    },

    CLOSE_ALL_MODALS({ commit }) {
      commit('CLOSE_ALL_MODALS');
    },

    UPDATE_MODAL({ commit, state }, updatedModal) {
      if (!updatedModal.id) {
        console.warn('Невозможно обновить модальное окно: не указан ID');
        return null;
      }

      const index = state.modals.findIndex(
        (modal) => modal.id === updatedModal.id
      );
      if (index === -1) {
        console.warn(`Модальное окно с ID ${updatedModal.id} не найдено`);
        return null;
      }

      const currentModal = state.modals[index];
      const modalConfig = {
        ...currentModal,
        ...updatedModal,
        uuid: `modal-uuid-${Date.now()}`,
      };

      commit('UPDATE_MODAL', { modal: modalConfig, index });
      return modalConfig;
    },
  },
};
