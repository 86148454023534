<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M1.16665 22.7501H12.8334C13.4775 22.7493 13.9993 22.2275 14.0001 21.5835V13.4166C13.9993 12.7726 13.4775 12.2508 12.8334 12.25H1.16665C0.522575 12.2508 0.00080109 12.7726 0 13.4166V21.5835C0.00080109 22.2275 0.522575 22.7493 1.16665 22.7501ZM1.16665 13.4166H12.8334L12.8339 21.5835H1.16665V13.4166Z"
        fill="currentColor"
      />
      <path
        d="M1.16665 35.0001H5.83324C6.47732 34.9993 6.99936 34.4775 6.99989 33.8335V25.6666C6.99936 25.0228 6.47732 24.5008 5.83324 24.5H1.16665C0.522575 24.5008 0.00080109 25.0228 0 25.6666V33.8335C0.00080109 34.4775 0.522575 34.9993 1.16665 35.0001ZM1.16665 25.6666H5.83324L5.83405 33.8335H1.16665V25.6666Z"
        fill="currentColor"
      />
      <path
        d="M33.8334 0H1.16665C0.522575 0.00080109 0.00080109 0.522575 0 1.16665V9.33346C0.00080109 9.97726 0.522575 10.4993 1.16665 10.5001H17.2287C17.5577 11.0593 17.8399 11.6446 18.073 12.2499H16.9165C16.2727 12.2507 15.7507 12.7725 15.7499 13.4166V21.5834C15.7507 22.2275 16.2727 22.7493 16.9165 22.7501H18.0394C18.0314 23.0136 18.0159 23.2785 18.0188 23.5389C18.0228 23.8475 18.0378 24.1709 18.0578 24.4999H9.91665C9.27258 24.5007 8.7508 25.0227 8.75 25.6665V33.8334C8.7508 34.4774 9.27258 34.9992 9.91665 35H33.8334C34.4774 34.9992 34.9992 34.4774 35 33.8334V1.16665C34.9992 0.522575 34.4774 0.00080109 33.8334 0V0ZM1.16665 1.16665H10.9332C10.8958 1.98402 10.9223 2.803 11.0128 3.6161C11.3356 5.29091 12.643 6.13659 13.7966 6.88293L13.984 7.00417C14.9357 7.63248 15.7715 8.42049 16.4548 9.33346H1.16665V1.16665ZM16.9165 13.4166H18.4472C18.7136 14.3899 18.8581 15.3926 18.8768 16.4014C18.86 17.3027 18.7358 18.1985 18.5072 19.0704C18.4204 19.4637 18.3334 19.8603 18.2616 20.2688C18.1857 20.6961 18.1361 21.1383 18.0987 21.5834H16.9165V13.4166ZM9.91665 25.6665H18.174C18.4848 28.6402 19.6183 31.4683 21.4475 33.8334H9.91665V25.6665ZM33.8334 33.8334H22.9949C20.8034 31.3983 19.4902 28.2992 19.2653 25.0307C19.2181 24.5076 19.1914 24.0005 19.1858 23.5239C19.1799 23.0886 19.1919 22.6443 19.2207 22.1946C19.2477 21.6168 19.3113 21.0413 19.4103 20.4715C19.48 20.0792 19.5636 19.6984 19.6469 19.3209C19.896 18.3609 20.0293 17.3748 20.044 16.383C20.0229 15.1197 19.8232 13.8657 19.4506 12.6582C19.1185 11.5874 18.6517 10.5634 18.061 9.61037C17.1937 8.17589 16.0188 6.95183 14.6209 6.02657L14.43 5.90294C13.3341 5.19371 12.3867 4.58141 12.1605 3.41129C12.0919 2.66521 12.0697 1.9154 12.094 1.16665H33.8334V33.8334Z"
        fill="currentColor"
      />
      <path
        d="M13.33 3.28387C13.5049 3.89002 14.1025 4.30178 15.0635 4.92289L15.2606 5.0508C16.7989 6.06658 18.0927 7.4116 19.0476 8.98867C19.6941 10.0314 20.2049 11.1521 20.5683 12.3241C20.665 12.6315 20.9924 12.8021 21.2997 12.7057C21.6071 12.609 21.778 12.2814 21.6813 11.9741C21.2888 10.7089 20.7368 9.4987 20.0391 8.37264C18.9939 6.6503 17.5792 5.18191 15.8972 4.07321L15.6975 3.9437C15.2427 3.68949 14.8277 3.37012 14.4656 2.99574L14.4566 2.87798C14.4352 2.55621 14.1572 2.31295 13.8357 2.33431C13.5142 2.35594 13.271 2.63392 13.2923 2.95542C13.2982 3.039 13.3038 3.11484 13.3097 3.17866C13.3134 3.21417 13.3201 3.24942 13.33 3.28387Z"
        fill="currentColor"
      />
      <path
        d="M21.2092 16.3623C21.1959 17.4574 21.0511 18.5469 20.778 19.6075C20.7005 19.9581 20.6231 20.3109 20.5593 20.6735C20.4669 21.2041 20.4084 21.7398 20.3838 22.2778C20.3577 22.6877 20.3467 23.1027 20.3518 23.5099C20.3571 23.9564 20.3822 24.429 20.4266 24.9185C20.6191 27.6643 21.6303 30.2892 23.3294 32.4548C23.46 32.6225 23.6715 32.7058 23.8814 32.6727C24.0913 32.6393 24.2664 32.4946 24.3385 32.2949C24.4109 32.0949 24.3687 31.8716 24.2285 31.712C22.6888 29.7215 21.7703 27.3209 21.5881 24.8111C21.5473 24.3545 21.5241 23.912 21.5187 23.4955C21.5134 23.1174 21.5235 22.7336 21.5489 22.3435C21.5708 21.8516 21.6239 21.3619 21.7078 20.8767C21.7692 20.5309 21.844 20.1934 21.9254 19.822C22.2159 18.685 22.3671 17.5167 22.3759 16.3431C22.3708 16.0248 22.111 15.7693 21.7927 15.7695H21.7831C21.6282 15.7719 21.4808 15.8358 21.3734 15.9471C21.2658 16.0582 21.2068 16.2077 21.2092 16.3623Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Renovation',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
};
</script>
