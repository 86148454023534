import Vue from 'vue';

// Создаем реактивный объект
const windowSizeState = Vue.observable({
  size: getWindowSize(), // sm, md, lg
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  width: null,
  height: null,
});

// Функция определения размера окна
function getWindowSize() {
  const remSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  );
  const width = window.innerWidth / remSize;
  if (width < 48) return 'sm'; // 768px / 16px (default rem)
  if (width < 64) return 'md'; // 1024px / 16px (default rem)
  return 'lg';
}

// Функция для обновления состояния
function updateWindowSize() {
  const size = getWindowSize();
  windowSizeState.size = size;
  windowSizeState.height = window.innerHeight;
  windowSizeState.width = window.innerWidth;
  windowSizeState.isMobile = size === 'sm';
  windowSizeState.isTablet = size === 'md';
  windowSizeState.isDesktop = size === 'lg';
}

// Устанавливаем начальные значения
updateWindowSize();

// Слушаем событие resize
window.addEventListener('resize', updateWindowSize);

// Добавляем в прототип Vue
Vue.prototype.$windowSize = windowSizeState;
