<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.20312 18.0469C8.20312 18.6501 8.69367 19.1406 9.29688 19.1406V20.2344C9.29688 20.8376 9.78742 21.3281 10.3906 21.3281H12.5781C13.1813 21.3281 13.6719 20.8376 13.6719 20.2344V19.1406C14.2751 19.1406 14.7656 18.6501 14.7656 18.0469V16.9531H15.8594V15.8594H14.7656V14.7656C14.7656 14.1624 14.2751 13.6719 13.6719 13.6719H9.29688C8.69367 13.6719 8.20312 14.1624 8.20312 14.7656V15.8594H7.10938V16.9531H8.20312V18.0469ZM12.5781 20.2344H10.3906V19.1406H12.5781V20.2344ZM9.29688 14.7656H13.6719L13.673 18.0469H9.29688V14.7656Z"
      fill="currentColor"
    />
    <path
      d="M31.5713 31.6865C31.2448 31.1423 30.693 30.7738 30.0777 30.6633V22.1348C31.9863 21.4572 33.359 19.6383 33.359 17.5V15.8594C33.359 15.2562 32.8685 14.7656 32.2652 14.7656H24.609V13.6719H23.5153V14.7656H22.4215V10.3906C22.4215 9.78742 22.912 9.29688 23.5153 9.29688C24.1185 9.29688 24.609 9.78742 24.609 10.3906V11.4844H25.7028V10.3906C25.7028 9.18422 24.7217 8.20312 23.5153 8.20312C22.3088 8.20312 21.3278 9.18422 21.3278 10.3906V14.7656H19.1403C18.537 14.7656 18.0465 15.2562 18.0465 15.8594V17.5C18.0465 19.6383 19.4192 21.4572 21.3278 22.1348V30.6633C20.7125 30.7738 20.1607 31.1429 19.8342 31.6865L18.8307 33.3594H11.3757L10.6057 29.8949L12.6193 29.7691C15.0496 29.6171 16.9528 27.5909 16.9528 25.1562C16.9528 24.2517 16.2167 23.5156 15.3121 23.5156H6.46971L4.39978 12.3812C4.30353 11.8612 3.84635 11.4844 3.31205 11.4844H1.65228C1.32033 11.4844 1.00861 11.6304 0.797518 11.8836C0.590253 12.133 0.505487 12.4584 0.564003 12.7755L2.67166 24.1123V33.3594H0.546503V34.4531H33.9059V33.3594H32.5748L31.5713 31.6865ZM19.1403 17.5V15.8594H32.2652V17.5C32.2652 19.6104 30.5475 21.3281 28.4371 21.3281H22.9684C20.858 21.3281 19.1403 19.6104 19.1403 17.5ZM22.9684 22.4219H28.4371C28.622 22.4219 28.8041 22.4098 28.984 22.3896V30.625H22.4215V22.3896C22.6014 22.4098 22.7835 22.4219 22.9684 22.4219ZM20.7716 32.2498C20.9685 31.9222 21.3278 31.7188 21.7095 31.7188H29.696C30.0777 31.7188 30.437 31.9222 30.6339 32.2498L31.2995 33.3594H20.106L20.7716 32.2498ZM15.859 25.1562C15.859 25.3422 15.8404 25.5243 15.8125 25.7031H3.7665V24.6094H15.3121C15.614 24.6094 15.859 24.8544 15.859 25.1562ZM1.65228 12.5781L3.32408 12.5803L5.35736 23.5156H3.66588L1.65228 12.5781ZM3.7665 26.7969H15.4516C14.8938 27.8523 13.8164 28.5983 12.551 28.677L7.62142 28.9849L7.68978 30.0765L9.50049 29.9633L10.2552 33.3594H3.7665V26.7969Z"
      fill="currentColor"
    />
    <path
      d="M33.3594 0.546875H18.0469C17.4437 0.546875 16.9531 1.03742 16.9531 1.64062V10.3906C16.9531 10.9938 17.4437 11.4844 18.0469 11.4844H20.2344V10.3906H18.0469V1.64062H33.3594V10.3906H26.7969V11.4844H33.3594C33.9626 11.4844 34.4531 10.9938 34.4531 10.3906V1.64062C34.4531 1.03742 33.9626 0.546875 33.3594 0.546875Z"
      fill="currentColor"
    />
    <path
      d="M20.9385 5.85742L21.7119 5.08402L23.3526 6.72479L22.5792 7.49819L20.9385 5.85742Z"
      fill="currentColor"
    />
    <path
      d="M23.126 3.66992L23.8993 2.89664L28.2737 7.27109L27.5004 8.04437L23.126 3.66992Z"
      fill="currentColor"
    />
    <path
      d="M26.9531 3.6709L27.7264 2.89762L30.4603 5.63155L29.6871 6.40483L26.9531 3.6709Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Bathroom',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
};
</script>
