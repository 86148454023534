<template>
  <div
    id="app"
    :style="{
      '--app-height': `${$windowSize.height}px`,
    }"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  // TODO: set theme before Vue loaded so we dont get flashbanged
  created() {
    let currentTheme;
    try {
      currentTheme = localStorage.getItem('theme');
    } catch (e) {
      currentTheme = 'light';
    }
    if (!currentTheme) {
      currentTheme = 'light';
    }
    this.$changeTheme(currentTheme, false);
  },
};
</script>
