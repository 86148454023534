// import langMixin from '@/router/langMixin';
import router from '@/router';

import Vue from 'vue';
import i18n from '@/libs/i18n';

export default {
  state: () => ({
    chessSettings: null,
    infoSettings: null,
    interactiveSettings: null,
    premisesSettings: null,
    catalogSettings: null,
    layoutSettings: null,
    templates: null,
    currentLanguage: null,
    currentCurrency: null,
    areaUnit: 'm',
    catalogs: null,
    firstToken: null,
    secondToken: null,
    isTokenLoaded: false,
  }),
  mutations: {
    SET_TOKEN_LOADED(state, value) {
      state.isTokenLoaded = value;
    },

    SET_FIRST_TOKEN(state, token) {
      state.firstToken = token;
    },
    SET_SECOND_TOKEN(state, token) {
      state.secondToken = token;
    },

    SET_CHESS_SETTINGS(state, settings) {
      state.chessSettings = settings;
    },
    SET_LAYOUT_SETTINGS(state, settings) {
      state.layoutSettings = settings;
    },
    SET_CURRENT_CURRENCY(state, currency) {
      state.currentCurrency = currency;
    },
    SET_AREA_UNIT(state, areaUnit) {
      state.areaUnit = areaUnit;
    },
    SET_CURRENT_LANGUAGE(state, language) {
      state.currentLanguage = language;
    },
    SET_INFO_SETTINGS(state, settings) {
      state.infoSettings = settings;
    },
    SET_INTERACTIVE_SETTINGS(state, settings) {
      state.interactiveSettings = settings;
    },
    SET_PREMISES_SETTINGS(state, settings) {
      state.premisesSettings = settings;
    },
    SET_CATALOG_SETTINGS(state, settings) {
      state.catalogSettings = settings;
    },
    SET_CATALOGS(state, catalogs) {
      state.catalogs = catalogs;
    },
    SET_TEMPLATES(state, templates) {
      state.templates = templates;
    },
  },
  actions: {
    SET_AREA_UNIT(ctx, areaUnit) {
      ctx.commit('SET_AREA_UNIT', areaUnit);
    },
    SET_CURRENT_CURRENCY(ctx, currency) {
      ctx.commit('SET_CURRENT_CURRENCY', currency);
    },
    SET_DEFAULT_COLOR_THEME(ctx, theme) {
      try {
        const theme = localStorage.getItem('theme');
        Vue.prototype.$changeTheme(theme);
      } catch (e) {
        // Некоторые браузеры, например Safari в режиме инкогнито, могут выбрасывать
        // исключение при доступе к localStorage (ReferenceError или QuotaExceededError).
        // https://stackoverflow.com/questions/14555347/why-does-localstorage-throw-a-quota-exceeded-error-in-ios
        Vue.prototype.$changeTheme(theme);
      }
    },
    SET_COLOR_SCHEME(ctx, color_scheme) {
      document.documentElement.style.setProperty(
        '--primary',
        color_scheme.primary
      );
      // TODO: AUTO CALCULATE ?
      document.documentElement.style.setProperty(
        '--primary-active',
        color_scheme.primary_active
      );
    },
    SET_LANGUAGE(ctx, lang) {
      // let bootstrapCssCdn;
      i18n.locale = lang.name;
      if (lang.is_rtl) {
        document.documentElement.setAttribute('dir', 'rtl');
        document.documentElement.setAttribute('lang', lang.name);
      } else {
        document.documentElement.setAttribute('dir', 'ltr');
        document.documentElement.setAttribute('lang', lang.name);
      }
      ctx.commit('SET_CURRENT_LANGUAGE', lang);
    },

    async GET_TOKEN(ctx, { firstToken, secondToken, lang }) {
      ctx.commit('SET_TOKEN_LOADED', false);
      ctx.dispatch('LOAD_START', 'GET_TOKEN');
      ctx.commit('SET_FIRST_TOKEN', firstToken);
      ctx.commit('SET_SECOND_TOKEN', secondToken);
      await ctx
        .dispatch('axiosWrapped', {
          url: 'public/token',
          params: {
            lang: lang ?? '',
          },
        })
        .then(async function (response) {
          const data = response.data.data;

          ctx.commit('SET_CHESS_SETTINGS', data.chess);
          ctx.commit('SET_INFO_SETTINGS', data.info);
          ctx.commit('SET_INTERACTIVE_SETTINGS', data.interactive);
          ctx.commit('SET_PREMISES_SETTINGS', data.premises);
          ctx.commit('SET_TEMPLATES', data.templates);
          data.settings.short_type =
            data.settings.type === 'InteractiveCatalogue' ? 'ic' : 'gic';
          ctx.commit('SET_CATALOG_SETTINGS', data.settings);
          ctx.commit('SET_LAYOUT_SETTINGS', data.layout);
          ctx.commit('SET_CATALOGS', data.catalogs);

          ctx.dispatch('SET_COLOR_SCHEME', data.layout.color_scheme);
          ctx.dispatch(
            'SET_DEFAULT_COLOR_THEME',
            data.layout.color_scheme.theme
          );
          ctx.dispatch('INIT_FAVORITES');
          // SET DEFAULT PAGE TITlE, WITHOUT PREFIX
          ctx.dispatch('SET_TITLE');
          ctx.dispatch('SET_LANGUAGE', data.current_lang);
          ctx.dispatch('SET_ANALYTICS_SETTINGS', data.analytics);
          ctx.dispatch('LOAD_END', 'GET_TOKEN');
          ctx.commit('SET_TOKEN_LOADED', true);
        })
        .catch((error) => {
          console.log('error in get token', error);
          ctx.dispatch('LOAD_END', 'GET_TOKEN');
          router.push({ name: 'error-404' });
        });
    },
  },
  getters: {
    chessSettings: (state) => state.chessSettings,
    catalogs: (state) => state.catalogs,
    infoSettings: (state) => state.infoSettings,
    interactiveSettings: (state) => state.interactiveSettings,
    premisesSettings: (state) => state.premisesSettings,
    catalogSettings: (state) => state.catalogSettings,
    layoutSettings: (state) => state.layoutSettings,
    templates: (state) => state.templates,
    currentLanguage: (state) => state.currentLanguage,
    areaUnit: (state) => state.areaUnit,
    currentCurrency: (state) => state.currentCurrency,
    firstToken: (state) => state.firstToken,
    secondToken: (state) => state.secondToken,
    isRtlLang: (getters) => getters.currentLanguage?.is_rtl,
    isTokenLoaded: (state) => state.isTokenLoaded,
  },
};
