<template>
  <svg
    fill="none"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    class="lucide lucide-icon"
  >
    <path
      d="M1 20V11.5088C1 11.1579 1.24561 10.8421 1.59649 10.7719L6.4386 9.64912C6.47368 9.64912 6.54386 9.61403 6.61404 9.61403C6.64912 9.61403 6.68421 9.61403 6.7193 9.61403L7.07018 9.68421V1.89474C7.07018 1.54386 7.31579 1.26316 7.63158 1.15789L12.0526 0H12.1228C12.1579 0 12.193 0 12.2281 0C12.2632 0 12.2982 0 12.2982 0C12.3333 0 12.3684 0 12.4035 0.0350877C12.4386 0.0350877 12.4737 0.0350877 12.5088 0.0701754L18.1228 2.38597C18.4035 2.49123 18.5789 2.77193 18.5789 3.08772V20H1ZM17.0702 18.4912V3.57895L12.9649 1.85965V18.4912H17.0702ZM11.4561 18.4912V12.1404L7.31579 11.2982V18.4912H11.4561ZM5.84211 18.4912V11.3333L2.50877 12.1053V18.4912H5.84211ZM11.4561 10.5965V1.7193L8.54386 2.49123V10L11.4561 10.5965Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Interactive",
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
};
</script>
