<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="lucide lucide-icon"
  >
    <path
      d="M4.00741 13.9601C3.52376 13.9601 3.11854 13.568 3.11854 13.0712L3.10547 8.7707C3.10547 8.53541 3.19697 8.3132 3.3669 8.14327C3.53683 7.97334 3.75905 7.88184 3.99433 7.88184H5.19692C6.37336 7.88184 7.31451 8.82299 7.31451 9.97328C7.31451 11.1236 6.36029 12.0647 5.19692 12.0647C5.11849 12.0647 5.01392 12.0647 4.8832 12.0647V13.0582C4.89627 13.568 4.49105 13.9601 4.00741 13.9601ZM4.8832 10.3262C5.00084 10.3262 5.11849 10.3262 5.19692 10.3262C5.39299 10.3262 5.54985 10.1694 5.54985 9.99943C5.54985 9.8295 5.37992 9.67264 5.19692 9.67264H4.8832C4.8832 9.79028 4.8832 10.1955 4.8832 10.3262Z"
      fill="currentColor"
    />
    <path
      d="M8.84334 13.8425C8.60806 13.8425 8.38584 13.751 8.21591 13.581C8.04598 13.4111 7.95448 13.1889 7.95448 12.9536C7.95448 12.9405 7.94141 9.46349 7.94141 8.7707C7.94141 8.53541 8.03291 8.3132 8.20284 8.14327C8.37277 7.97334 8.59498 7.88184 8.83027 7.88184H10.0198C11.5622 7.88184 12.5949 9.07135 12.5949 10.8491C12.5949 12.5353 11.5361 13.7902 10.0721 13.8163C9.71914 13.8294 8.85641 13.8425 8.84334 13.8425ZM9.71914 12.0647C9.83678 12.0647 9.95442 12.0647 10.0329 12.0647C10.6472 12.0517 10.8171 11.3066 10.8171 10.8621C10.8171 10.4961 10.7387 9.65957 10.0067 9.65957H9.70607C9.71914 9.65957 9.71914 11.4765 9.71914 12.0647Z"
      fill="currentColor"
    />
    <path
      d="M14.2687 13.8949C13.7851 13.8949 13.3799 13.5028 13.3799 13.006V8.73164C13.3799 8.24799 13.772 7.84277 14.2687 7.84277H16.0073C16.4909 7.84277 16.8961 8.23492 16.8961 8.73164C16.8961 9.22836 16.504 9.6205 16.0073 9.6205H15.1576V9.94729H15.8635C16.3471 9.94729 16.7523 10.3394 16.7523 10.8362C16.7523 11.3329 16.3602 11.725 15.8635 11.725H15.1576V13.0191C15.1576 13.4897 14.7655 13.8949 14.2687 13.8949Z"
      fill="currentColor"
    />
    <path
      d="M4.53003 19.9733C3.41894 19.9733 2.51701 19.0714 2.51701 17.9603V16.3133H2.41243C1.30135 16.3133 0.399414 15.4114 0.399414 14.3003V7.50307C0.399414 6.39199 1.30135 5.49005 2.41243 5.49005H2.51701V2.01302C2.51701 0.901937 3.41894 0 4.53003 0H12.0723C12.935 0 13.7716 0.352932 14.3598 0.980367L16.7127 3.46396C17.2356 4.01297 17.5362 4.71883 17.5754 5.47698H17.68C18.7911 5.47698 19.693 6.37892 19.693 7.49V14.2872C19.693 15.3983 18.7911 16.3002 17.68 16.3002H17.5754V17.9472C17.5754 19.0583 16.6735 19.9603 15.5624 19.9603H4.53003V19.9733ZM4.28167 17.9603C4.28167 18.1041 4.39931 18.2087 4.53003 18.2087H15.5624C15.7062 18.2087 15.8108 18.091 15.8108 17.9603V16.3133H4.28167V17.9603ZM2.41243 7.24164C2.26865 7.24164 2.16407 7.35928 2.16407 7.49V14.2872C2.16407 14.431 2.28172 14.5356 2.41243 14.5356H17.6669C17.8107 14.5356 17.9153 14.4179 17.9153 14.2872V7.49C17.9153 7.34621 17.7977 7.24164 17.6669 7.24164H2.41243ZM15.7977 5.47698C15.7585 5.17634 15.6408 4.90183 15.4317 4.67962L13.0788 2.19602C12.8174 1.92152 12.4514 1.76466 12.0723 1.76466H4.53003C4.38624 1.76466 4.28167 1.8823 4.28167 2.01302V5.47698H15.7977Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'PdfFile',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
};
</script>
