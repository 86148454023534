<template>
  <svg
    fill="none"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    class="lucide lucide-icon"
  >
    <path
      d="M18.8833 0H0.899224C0.402647 0 0 0.402593 0 0.899224V19.0531C0 19.5497 0.402593 19.9523 0.899224 19.9523H18.8833C19.3799 19.9523 19.7825 19.5497 19.7825 19.0531V0.89917C19.7825 0.402593 19.3799 0 18.8833 0ZM17.9841 7.19363H15.2865C14.79 7.19363 14.3873 7.59622 14.3873 8.09285C14.3873 8.58948 14.7899 8.99207 15.2865 8.99207H17.9841V18.1539H6.59418V15.4563C6.59418 14.9597 6.19159 14.5571 5.69496 14.5571C5.19833 14.5571 4.79573 14.9597 4.79573 15.4563V18.1539H1.79839V1.79839H4.79573V11.6896C4.79573 12.1862 5.19833 12.5889 5.69496 12.5889C6.19159 12.5889 6.59418 12.1863 6.59418 11.6896V8.99202H11.0902C11.5868 8.99202 11.9894 8.58943 11.9894 8.0928C11.9894 7.59617 11.5868 7.19357 11.0902 7.19357H6.59418V1.79839H17.9841V7.19363H17.9841Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'PremisesLayout',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
};
</script>
