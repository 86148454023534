export function calculateSimilarity(instance1, instance2) {
  const weights = {
    tags: 20,
    price: 15,
    area_total: 15,
    rooms: 30,
    storey: 20,
  };

  let similarity = 0;
  let data = {};

  const sharedTags = instance1.tags?.filter((tag1) =>
    instance2?.tags?.some((tag2) => tag1?.id === tag2?.id)
  );
  if (instance1.tags?.length) {
    similarity += (sharedTags.length / instance1.tags?.length) * weights.tags;
  }
  data.tags = similarity;

  let arg;

  if (instance1.price !== null && instance2.price !== null) {
    arg = Math.ceil(Math.abs(100 - (instance2.price / instance1.price) * 100));
    if (arg == 0) {
      similarity += weights.price;
      data.price = weights.price;
    } else {
      similarity += weights.price / (1 + arg / 100);
      data.price = weights.price / (1 + arg / 100);
    }
  }

  if (instance1?.layout?.area_total && instance2?.layout?.area_total) {
    arg = Math.ceil(
      Math.abs(
        100 - (instance2.layout.area_total / instance1.layout.area_total) * 100
      )
    );
    if (arg == 0) {
      similarity += weights.area_total;
      data.areaTotal = weights.area_total;
    } else {
      data.areaTotal = weights.area_total / (1 + arg / 100);
      similarity += weights.area_total / (1 + arg / 100);
    }
  }

  if (instance1?.layout?.label === instance2?.layout?.label) {
    similarity += weights.rooms;
    data.rooms = weights.rooms;
  }

  arg = Math.abs(instance1.storey.order - instance2.storey.order);
  similarity += weights.storey - 0.05 * weights.storey * arg;
  data.storey = weights.storey - 0.05 * weights.storey * arg;

  return [similarity, data];
}
