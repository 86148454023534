import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';

// import MainLayout from '@/views/Layouts/MainLayout.vue';
const CatalogLayout = () =>
  import(/* webpackChunkName: "CatalogLayout" */ '@/layouts/CatalogLayout');
const PdfLayout = () =>
  import(/* webpackChunkName: "PDFLayout" */ '@/layouts/PdfLayout');
const PageInteractive = () =>
  import(/* webpackChunkName: "PageInteractive" */ '@/views/PageInteractive');
const PagePremises = () =>
  import(/* webpackChunkName: "PagePremises" */ '@/views/PagePremises');
const PageChess = () =>
  import(/* webpackChunkName: "PageChess" */ '@/views/PageChess');
const PageInfo = () =>
  import(/* webpackChunkName: "PageInfo" */ '@/views/PageInfo');
const PageCatalogs = () =>
  import(/* webpackChunkName: "PageCatalogs" */ '@/views/PageCatalogs');

// const Main = () => import(/* webpackChunkName: "Main" */ '@/components/Main');
const PageFavorites = () =>
  import(/* webpackChunkName: "PageFavorites" */ '@/views/PageFavorites');
const PageTokenNotFound = () =>
  import(
    /* webpackChunkName: "PageTokenNotFound" */ '@/views/PageTokenNotFound'
  );

const InstancePdf = () =>
  import(/* webpackChunkName: "InstancePdf" */ '@/views/Pdf/InstancePdf');
const InstructionsPdf = () =>
  import(
    /* webpackChunkName: "InstructionsPdf" */ '@/views/Pdf/InstructionsPdf'
  );
const ChessPdf = () =>
  import(/* webpackChunkName: "ChessPdf" */ '@/views/Pdf/ChessPdf');

Vue.use(VueRouter);

const routes = [
  {
    path: '/error-404',
    name: 'error-404',
    component: PageTokenNotFound,
  },
  { path: '*', redirect: 'error-404' },

  // Фикс для того чтобы instructions не читался как secondToken
  {
    path: '/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:firstToken/:secondToken?/instructions',
    beforeEnter: (to, from, next) => {
      next({
        name: 'InstructionsPdf',
        params: {
          ...to.params,
        },
        query: to.query,
      });
    },
  },
  // {
  //   path: '/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:firstToken/:secondToken?/pdf/:id',
  //   beforeEnter: (to, from, next) => {
  //     next({
  //       name: 'InstancePdf',
  //       params: {
  //         ...to.params,
  //       },
  //       query: to.query,
  //     });
  //   },
  // },
  {
    path: '/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:firstToken/:secondToken?/pdf/chess/:id?',
    beforeEnter: (to, from, next) => {
      next({
        name: 'ChessPdf',
        params: {
          ...to.params,
        },
        query: to.query,
      });
    },
  },

  {
    path: '/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:firstToken/:secondToken?',
    name: 'Main',
    component: CatalogLayout,
    children: [
      // REDIRECTS
      {
        path: 'interactive',
        beforeEnter: (to, from, next) => {
          next({
            name: 'Main',
            params: {
              ...to.params,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'premises/:instanceId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'Premises-details-sidebar',
            params: {
              ...to.params,
              sidebarContext: 'instance',
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'chess/:instanceId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'Chess-details-sidebar',
            params: {
              ...to.params,
              sidebarContext: 'instance',
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },

      {
        path: 'chess/storey-sidebar/:storeyId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'Chess-group-sidebar',
            params: {
              ...to.params,
              sidebarContext: to.params.storeyId,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'chess/storey-sidebar/:storeyId/instance/:instanceId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'Chess-details-sidebar',
            params: {
              ...to.params,
              sidebarContext: 'instance',
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },

      {
        path: 'chess/estate/:estateId/storey-sidebar/:storeyId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessWithEstate-group-sidebar',
            params: {
              ...to.params,
              sidebarContext: to.params.storeyId,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'chess/estate/:estateId/storey-sidebar/:storeyId/instance/:instanceId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessWithEstate-details-sidebar',
            params: {
              ...to.params,
              sidebarContext: 'instance',
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'chess/estate/:estateId/instance/:instanceId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessWithEstate-details-sidebar',
            params: {
              ...to.params,
              sidebarContext: 'instance',
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'chessPlus',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessPlus',
            params: {
              ...to.params,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'chessPlus/storey-sidebar/:storeyId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessPlus-group-sidebar',
            params: {
              ...to.params,
              sidebarContext: to.params.storeyId,
            },
            query: to.query,
          });
        },
      },

      {
        path: 'chessPlus/storey-sidebar/:storeyId/instance/:instanceId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessPlus-details-sidebar',
            params: {
              ...to.params,
              sidebarContext: 'instance',
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'chessPlus/:instanceId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessPlus-details-sidebar',
            params: {
              ...to.params,
              sidebarContext: 'instance',
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'chessPlus/estate/:estateId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessPlusWithEstate',
            params: {
              ...to.params,
            },
            query: to.query,
          });
        },
      },

      {
        path: 'chessPlus/estate/:estateId/instance/:instanceId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessPlus-details-sidebar',
            params: {
              ...to.params,
              sidebarContext: 'instance',
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'chessPlus/estate/:estateId/storey-sidebar/:storeyId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessPlus-group-sidebar',
            params: {
              ...to.params,
              sidebarContext: to.params.storeyId,
            },
            query: to.query,
          });
        },
      },
      {
        path: 'chessPlus/estate/:estateId/storey-sidebar/:storeyId/instance/:instanceId',
        beforeEnter: (to, from, next) => {
          next({
            name: 'ChessPlus-details-sidebar',
            params: {
              ...to.params,
              sidebarContext: 'instance',
              sidebarSubcontext: to.params.instanceId,
            },
            query: to.query,
          });
        },
      },

      // ROUTES

      {
        path: 'projects/',
        name: 'Projects',
        component: PageCatalogs,
        meta: {
          pageTitle: 'Projects',
          breadcrumb: [
            {
              text: 'Projects',
              to: { name: 'Projects' },
              active: true,
            },
          ],
        },
      },
      {
        path: 'info/',
        name: 'Info',
        component: PageInfo,
        meta: {
          pageTitle: 'Info',
          breadcrumb: [
            {
              text: 'Info',
              to: { name: 'Info' },
              active: true,
            },
          ],
        },
      },
      // {
      //   path: "panorama/",
      //   name: "Panorama",
      //   component: PagePanorama,
      //   meta: {
      //     pageTitle: "Panorama",
      //     breadcrumb: [
      //       {
      //         text: "Panorama",
      //         to: { name: "Panorama" },
      //         active: true,
      //       },
      //     ],
      //   },
      // },
      {
        path: 'interactive/:interactiveId/frame/:frameId/:storeyId?',
        name: 'Interactive',
        component: PageInteractive,
        meta: {
          pageTitle: 'Interactive',
          handleEnterSidebarClassImmediately: false,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: 'Interactive',
              to: { name: 'Interactive', params: { reset: true } },
              active: true,
            },
          ],
        },
      },
      {
        path: 'premises',
        name: 'Premises',
        component: PagePremises,
        meta: {
          pageTitle: 'Premises',
          handleEnterSidebarClassImmediately: true,
          handleLeaveSidebarClassImmediately: true,

          breadcrumb: [
            {
              text: 'Premises',
              to: { name: 'Premises' },
              active: true,
            },
          ],
        },
      },
      {
        path: 'chess/',
        name: 'Chess',
        component: PageChess,
        meta: {
          pageTitle: 'Chess',
          handleEnterSidebarClassImmediately: false,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: 'Chess',
              to: { name: 'Chess' },
              active: true,
            },
          ],
        },
      },
      {
        path: 'chess/estate/:estateId',
        name: 'ChessWithEstate',
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: 'Chess',
          handleEnterSidebarClassImmediately: false,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: 'Chess',
              to: { name: 'Chess', params: { reset: true } },
            },
            {
              // text: 'Estate',
              to: { name: 'ChessWithEstate' },
              active: true,
              type: 'estateId',
            },
          ],
        },
      },

      {
        path: 'chess-plus',
        name: 'ChessPlus',
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: 'ChessPlus',
          handleEnterSidebarClassImmediately: false,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: 'Chess Plus',
              to: { name: 'Chess', params: { reset: true } },
            },
            {
              text: 'Estate',
              type: 'estateId',
              to: { name: 'ChessPlusWithEstate' },
              active: true,
            },
          ],
        },
      },
      {
        path: 'chess-plus/estate/:estateId',
        name: 'ChessPlusWithEstate',
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: 'ChessPlus',
          handleEnterSidebarClassImmediately: false,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: 'Chess Plus',
              to: { name: 'Chess', params: { reset: true } },
            },
            {
              text: 'Estate',
              type: 'estateId',
              to: { name: 'ChessPlusWithEstate' },
              active: true,
            },
          ],
        },
      },
      // {
      //   path: "info",
      //   name: "Info",
      //   component: PageInfo,
      //   meta: {
      //     pageTitle: "Info",
      //     breadcrumb: [
      //       {
      //         text: "Info",
      //         to: { name: "Info" },
      //         active: true,
      //       },
      //     ],
      //   },
      // },
      {
        path: 'favorites',
        name: 'Favorites',
        component: PageFavorites,
        meta: {
          pageTitle: 'Favorites',
          handleEnterSidebarClassImmediately: true,
          handleLeaveSidebarClassImmediately: true,
          breadcrumb: [
            {
              text: 'Favorites',
              to: { name: 'Favorites' },
              active: true,
            },
          ],
        },
      },

      // { path: "*", component: PageNotFound }
    ],
  },
  {
    path: '/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:firstToken/:secondToken?',
    component: PdfLayout,
    children: [
      {
        // path: '/:lang?/:firstToken/:secondToken?/instructions',
        path: 'instructions',
        name: 'InstructionsPdf',
        component: InstructionsPdf,
        meta: {
          album: false,
        },
      },
      {
        path: 'pdf/:id',
        name: 'Pdf',
        component: InstancePdf,
        meta: {
          album: false,
        },
      },
      {
        path: 'pdf/chess/:id?',
        name: 'ChessPdf',
        component: ChessPdf,
        meta: {
          album: true,
        },
      },
    ],
  },

  // {
  //   path: '/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:token/:projectToken?/pdf/:id',
  //   name: 'Pdf',
  //   component: Pdf,
  // },
  // {
  //   path: '/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:token/:projectToken?/instructions/',
  //   name: 'Instrutions',
  //   component: Instructions,
  // },
];

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes,
});

const sidebarTypes = ['details', 'info', 'preview', 'group']; // Типы сайдбаров

// Функция для добавления вложенных маршрутов сайдбаров
function addSidebarRoutes() {
  const allRoutes = router.getRoutes();

  allRoutes.forEach((route) => {
    if (
      route.name === undefined ||
      route.name === 'error-404' ||
      route.name === 'Pdf' ||
      route.name === 'Projects' ||
      route.name === 'Info' ||
      route.name === 'Panorama' ||
      route.name === 'Instrutions' ||
      route.name === 'Main'
    ) {
      return;
    }
    sidebarTypes.forEach((sidebarType) => {
      router.addRoute(route.name, {
        path: `${route.path}/${sidebarType}/:sidebarContext?/:sidebarSubcontext?`,
        name: `${route.name}-${sidebarType}-sidebar`,
        component: route.component,
        meta: {
          ...route.meta,
          isSidebar: true,
          sidebarType,
        },
        props: {
          ...route.props,
        },
      });
    });
  });
}
// Вызываем функцию для добавления маршрутов после инициализации маршрутизатора
addSidebarRoutes();

router.afterEach((to) => {
  const openedFromUrl = store.getters.openedFromUrl;
  if (openedFromUrl !== null) {
    window.parent.postMessage(
      {
        type: 'changeRPTModulePage',
        data: to.fullPath,
      },
      openedFromUrl
    );
  }
});

// // Обработка сохранения сайдбаров при переключении маршрутов
router.beforeEach((to, from, next) => {
  const lastSidebar = store.getters.lastSidebar;
  if (lastSidebar == null) {
    next();
    return;
  }
  const matchedTo = to.matched;
  const matchedFrom = from.matched;
  const isComponentChanged = !matchedTo.some((toRoute, index) => {
    const fromRoute = matchedFrom[index];
    return (
      fromRoute &&
      fromRoute.components === toRoute.components &&
      toRoute.name !== 'Main'
    );
  });
  if (!isComponentChanged) {
    if (from.meta.isSidebar === true && to.meta.isSidebar !== true) {
      store.dispatch('CLOSE_NOT_PERSIST_SIDEBARS');
      store.dispatch('CLOSE_SIDEBAR_MODAL');
    }
    next();
    return;
  }
  store.dispatch('CLOSE_NOT_PERSIST_SIDEBARS');
  store.dispatch('CLOSE_SIDEBAR_MODAL');

  next();
});

export default router;
