import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import interactive from '@/store/interactive';
import filters from '@/store/filters';
import favorites from '@/store/favorites';
import models from '@/store/models';
import crm from '@/store/crm';
import analytics from '@/store/analytics';
import settings from '@/store/settings';
import sidebar from '@/store/sidebar';
import modal from '@/store/modal';

Vue.use(Vuex);

export default new Vuex.Store({
  // eslint-disable-next-line no-undef
  strict: process.env.DEV,
  // todo import dynamicly like:  project: () => import('@/store/modules/project'),
  // layout: () => import('@/store/modules/layout'),
  modules: {
    interactive,
    filters,
    favorites,
    models,
    modal,
    sidebar,
    settings,
    crm,
    analytics,
  },
  state: {
    openedFromUrl: null,
    loadList: [],
    isLoaded: false,
    base_url: 'https://admin.realtyprotech.com/api/v1/',
    // base_url: 'http://127.0.0.1:8000/api/v1/',
    // base_url: 'https://rptadmin.dynamicov.com/api/v1/',
    allowed_langs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  },
  getters: {
    openedFromUrl(state) {
      if (!state.openedFromUrl) return null;
      let url = state.openedFromUrl;
      // Remove trailing slash if exists
      if (url.endsWith('/')) {
        url = url.slice(0, -1);
      }

      // Add https protocol if missing
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url;
      }

      return url;
    },

    base_url(state) {
      return state.base_url;
    },

    isLoaded(state) {
      return state.isLoaded && state.loadList.length === 0;
    },
    isBootLoaded(state) {
      return state.isLoaded;
    },
  },
  mutations: {
    SET_PARENT_URL: (state, openedFromUrl) => {
      state.openedFromUrl = openedFromUrl;
    },
    SET_IS_LOADED: (state, loaded) => {
      state.isLoaded = loaded;
    },

    LOAD_LIST_ADD: (state, response) => {
      state.loadList.push(response);
    },

    LOAD_LIST_REMOVE: (state, response) => {
      state.loadList.splice(state.loadList.indexOf(response), 1);
    },

    SET_LANGS_TO_STATE: (state, response) => {
      state.allowed_langs = response;
    },
  },
  actions: {
    async axiosWrapped(ctx, data) {
      let params = {};
      if (data.params) {
        params = data.params;
      }
      params.first_token = ctx.getters.firstToken;

      data.url = ctx.state.base_url + data.url;
      data.lang = ctx.getters.currentLanguage?.id;

      if (ctx.getters.secondToken) {
        params.second_token = ctx.getters.secondToken;
      }

      const config = {
        params: params,
      };

      if (data.signal) {
        config.signal = data.signal;
      }

      let headers = {
        'X-A': ctx.getters.isAdmin || false,
      };
      if (data.lang) {
        headers['X-Accept-Language'] = `${data.lang}`;
      }
      config.headers = headers;

      // Handle POST requests
      if (data.method === 'post') {
        return axios.post(`${data.url}`, data.body || {}, config);
      }

      // Default to GET request
      return axios.get(`${data.url}`, config);
    },

    SET_IS_LOADED({ commit }, loaded) {
      commit('SET_IS_LOADED', loaded);
    },

    LOAD_START({ commit }, item) {
      commit('LOAD_LIST_ADD', item);
    },

    LOAD_END({ commit }, item) {
      commit('LOAD_LIST_REMOVE', item);
    },

    SET_PARENT_URL(ctx, openedFromUrl) {
      ctx.commit('SET_PARENT_URL', openedFromUrl);
    },

    SET_TITLE({ getters }, value = null) {
      let title = '';
      if (value) {
        title += value + ' | ';
      }
      document.title = title + getters.catalogSettings.name;
    },

    async LOAD_CATALOG(
      { dispatch, getters, commit },
      { firstToken, secondToken, lang }
    ) {
      commit('SET_IS_LOADED', false);
      await dispatch('GET_TOKEN', { firstToken, secondToken, lang });
      if (getters.catalogSettings.type === 'InteractiveCatalogue') {
        // eslint-disable-next-line no-unused-vars
        const [projectResponse, chessResponse] = await Promise.all([
          dispatch('GET_PROJECT', getters.catalogSettings.project_id),
          dispatch('GET_CHESS', getters.catalogSettings.project_id),
        ]);
        dispatch('PROCESS_CHESS', chessResponse);
      }
      commit('SET_IS_LOADED', true);
    },
  },
});
