import router from '@/router/index';
import store from '@/store';

export default {
  methods: {
    getRouterConfig(config) {
      if (!config.params) {
        config.params = {};
      }
      let currentLang =
        config.params.lang ?? store.getters.currentLanguage?.name;
      delete config.params.lang;
      let params = {
        secondToken: config.params.secondToken || store.getters.secondToken,
        lang: currentLang,
      };
      if (store.getters.catalogSettings?.default_lang.name === currentLang) {
        delete params.lang;
      }
      config.params = { ...config.params, ...params };

      return config;
    },
    routerReplace(config) {
      const finalConfig = this.getRouterConfig(config);
      router.replace(finalConfig);
    },
    routerPush(config) {
      const finalConfig = this.getRouterConfig(config);
      router.push(finalConfig).then(() => {
        if (window.RPTAnalytics) {
          // eslint-disable-next-line no-undef
          RPTAnalytics.visit(window.location.href);
        }
      });
    },
  },
};
