<template>
  <svg
    fill="none"
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
    class="lucide lucide-icon"
  >
    <path
      d="M12.1266 7.01091L13 8.5772L13.8734 7.01091C14.8204 5.31266 16.5273 3.6001 19.0938 3.6001C22.4211 3.6001 25 6.19342 25 9.74196C25 11.6232 24.2147 13.2535 22.6686 15.0188C21.1159 16.7916 18.8881 18.5998 16.1047 20.859L16.0054 20.9396C16.0053 20.9396 16.0052 20.9397 16.0051 20.9398C14.238 22.3729 13.4739 23.0042 12.9997 23.3991C12.5318 23.0089 11.8408 22.4367 9.99459 20.9395L9.89536 20.859C7.11187 18.5998 4.88407 16.7916 3.33135 15.0188C1.78529 13.2535 1 11.6232 1 9.74196C1 6.19337 3.57887 3.6001 6.90625 3.6001C9.47273 3.6001 11.1796 5.31266 12.1266 7.01091Z"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "Heart",
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
};
</script>
