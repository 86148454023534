export default {
  state: () => ({
    filtersCount: 0,
    currentFilter: {},
  }),
  getters: {
    filter(state, getters) {
      let types = [
        {
          id: 0,
          name: 'all',
        },
      ];
      let minArea = Infinity;
      let maxArea = 0;
      let rooms = [];
      getters?.layouts?.forEach((layout) => {
        if (maxArea < layout.area_total * 1) {
          maxArea = layout.area_total * 1;
        }
        if (minArea > layout.area_total * 1) {
          minArea = layout.area_total * 1;
        }
        if (!types.some((type) => type.name === layout.type) && layout.type) {
          types.push({
            id: layout.id,
            name: layout.type,
          });
        }
        if (
          layout.type === 'living' &&
          !rooms.includes(layout.label) &&
          layout.label
        ) {
          rooms.push(layout.label);
        }
      });
      if (minArea == Infinity) {
        minArea = 0;
      }
      let maxPrice = 0;
      let minPrice = Infinity;

      getters?.instances?.forEach((instance) => {
        if (instance.status.type !== 1) {
          return;
        }
        if (maxPrice < instance.price) {
          maxPrice = instance.price;
        }
        if (minPrice > instance.price && instance.price > 0) {
          minPrice = instance.price;
        }
      });
      if (maxPrice == 0) {
        maxPrice = Infinity;
      }

      return {
        types: types,
        rooms: rooms.sort((a, b) => {
          const aNum = parseInt(a);
          const bNum = parseInt(b);
          if (!isNaN(aNum) && !isNaN(bNum)) {
            return aNum - bNum;
          }
          if (!isNaN(aNum)) return -1;
          if (!isNaN(bNum)) return 1;
          return a.localeCompare(b);
        }),
        area: {
          min: Math.floor(minArea),
          max: Math.ceil(maxArea),
        },
        price: {
          min: minPrice,
          max: maxPrice,
        },
      };
    },
    filtersCount(state) {
      return state.filtersCount;
    },
    currentFilter(state) {
      return state.currentFilter;
    },
  },
  mutations: {
    SET_FILTERS_COUNT(state, count) {
      state.filtersCount = count;
    },
    SET_CURRENT_FILTER(state, filter) {
      state.currentFilter = filter;
    },
  },
  actions: {
    SET_FILTERS_COUNT(ctx, count = 0) {
      ctx.commit('SET_FILTERS_COUNT', count);
    },
    SET_CURRENT_FILTER(ctx, filter = {}) {
      ctx.commit('SET_CURRENT_FILTER', filter);
    },
  },
};
