<script>
import * as icons from 'lucide-vue';
import { RPTIcons } from '@/assets/icons';

export default {
  name: 'LucideIcon',
  functional: true,
  props: {
    icon: {
      required: true,
      type: [String, Object],
    },
    size: {
      type: [String, Number],
      default: 14,
    },
    strokeWidth: {
      type: [String, Number],
      default: 2,
    },
    badge: {
      type: [String, Object, Number],
      default: null,
    },
    badgeClasses: {
      type: [String, Object, Array],
      default: '-top-3.5 -right-3.5',
    },
  },
  render(h, { props, data }) {
    // Create Feather Icon

    if (data.props && typeof data.props.size !== 'number') {
      data.props.size = Number(data.props.size);
    }

    const allIcons = { ...icons, ...RPTIcons };
    const IconComponent = allIcons[props.icon];

    if (!IconComponent) {
      console.warn(`Icon "${props.icon}" not found`);
      return null;
    }

    const svg = h(IconComponent, {
      props: {
        size: Number(props.size),
        strokeWidth: Number(props.strokeWidth),
      },
      ...data,
    });

    if (!props.badge) return svg;

    // Badge
    const badgeEl = h(
      'span',
      {
        staticClass:
          'absolute w-4 h-4 flex items-center justify-center truncate pt-0.25 rounded-full text-2xs shadow-lg shadow-red-400/30 bg-red-500  text-white',
        class: props.badgeClasses,
      },
      [props.badge]
    );

    // Return span with children
    return h('span', { staticClass: 'lucide-icon relative' }, [svg, badgeEl]);
  },
};
</script>

<style lang="scss">
svg.lucide {
  outline: none;
}
</style>
