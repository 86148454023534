import i18n from '@/libs/i18n';

export default {
  state: () => ({
    // interactive
    interactive: null,
    interactiveTranslate: 0,
    // tooltips
    tooltip_info: null,
    interactiveCache: {},
  }),
  getters: {
    tooltip_info(state) {
      return state.tooltip_info;
    },
    interactiveTranslate(state) {
      return state.interactiveTranslate;
    },
    interactive(state) {
      return state.interactive;
    },
  },
  mutations: {
    SET_TOOLTIP_INFO(state, data) {
      state.tooltip_info = data;
    },
    CACHE_INTERACTIVE(state, { key, data }) {
      state.interactiveCache = {
        ...state.interactiveCache,
        [key]: data,
      };
    },

    CLEAR_INTERACTIVE_CACHE(state) {
      state.interactiveCache = {};
    },

    SET_INTERACTIVE_TO_STATE: (state, response) => {
      state.interactive = response;
    },
  },
  actions: {
    GET_PROJECT_TOOLTIP_INFO(ctx, item) {
      if (item) {
        let data = ctx.rootGetters?.projectGroup?.projects?.find(
          (project) => project.id === item.id
        );
        data.type = 'project';
        ctx.commit('SET_TOOLTIP_INFO', data);
      } else {
        ctx.commit('SET_TOOLTIP_INFO', null);
      }
    },
    GET_ESTATE_TOOLTIP_INFO(ctx, item) {
      if (item) {
        let data = ctx.rootGetters.estates.find(
          (estate) => estate.id === item.id
        );
        data.type = 'estate';
        ctx.commit('SET_TOOLTIP_INFO', data);
      } else {
        ctx.commit('SET_TOOLTIP_INFO', null);
      }
    },
    GET_SECTION_TOOLTIP_INFO(ctx, item) {
      // console.log(item,'section')
      if (item) {
        let data = ctx.rootGetters.sections?.find(
          (section) => section.id === item.id
        );
        if (data) {
          data.type = 'section';
          data.name =
            i18n.t('titles.Section') +
            ' ' +
            i18n.t('titles.Number symbol') +
            data.number;
          ctx.commit('SET_TOOLTIP_INFO', data);
        }
      } else {
        ctx.commit('SET_TOOLTIP_INFO', null);
      }
    },
    GET_STOREY_TOOLTIP_INFO(ctx, item) {
      if (item) {
        let data = ctx.rootGetters?.storeys?.find(
          (storey) => storey.id === item.id
        );
        if (data) {
          data.name =
            i18n.t('titles.Storey') +
            ' ' +
            i18n.t('titles.Number symbol') +
            data.number;
          data.type = 'storey';
          ctx.commit('SET_TOOLTIP_INFO', data);
        }
      } else {
        ctx.commit('SET_TOOLTIP_INFO', null);
      }
    },
    GET_INSTANCE_TOOLTIP_INFO(ctx, item) {
      if (item) {
        let data = ctx.rootGetters.instances.find((i) => i.id == item.id);
        data.name = i18n.t('titles.Number symbol') + data.number;
        data.type = 'instance';
        ctx.commit('SET_TOOLTIP_INFO', data);
      } else {
        ctx.commit('SET_TOOLTIP_INFO', null);
      }
    },
    async GET_INTERACTIVE(ctx, { id, frame, storey }) {
      const cacheKey = `${id}_${frame}_${storey}`;
      const cachedData = ctx.state.interactiveCache[cacheKey];

      if (cachedData) {
        // ctx.commit("SET_INTERACTIVE_TO_STATE", cachedData);
        // cachedData.random = Math.random();
        return cachedData;
      }
      // ctx.dispatch('LOAD_START','GET_INTERACTIVE')
      return await ctx
        .dispatch('axiosWrapped', {
          url: `public/interactive/show?id=${id}&frame=${frame}&storey=${storey}`,
        })
        .then(function (response) {
          ctx.commit('CACHE_INTERACTIVE', {
            key: cacheKey,
            data: { ...response.data.data },
          });
          return response.data.data;
        })
        .catch(function (error) {
          console.log(error, 'error in fetching interactive');
        });
    },
    CLEAR_INTERACTIVE_CACHE(ctx) {
      ctx.commit('CLEAR_INTERACTIVE_CACHE');
    },
  },
};
